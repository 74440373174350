<style lang="scss" scoped>
</style>

<template>
  <!-- 用户新增/编辑 -->
  <section class="page-child-contianer">
    <el-form
      class="page-form"
      :model="formData"
      :rules="rules"
      ref="formData"
      label-width="84px"
    >
      <el-form-item label="业主名称:" prop="name">
        <el-input v-model="formData.name" placeholder="请输入业主名称" />
      </el-form-item>

    <el-form-item label="栋或区域:" prop="building_or_area">
        <el-input v-model="formData.building_or_area" placeholder="请输入栋或区域" />
      </el-form-item>
          <el-form-item label="单元:" prop="unit">
        <el-input v-model="formData.unit" placeholder="请输入单元" />
      </el-form-item>
          <el-form-item label="楼层:" prop="floor">
        <el-input v-model="formData.floor" placeholder="请输入楼层" />
      </el-form-item>
          <el-form-item label="门牌号:" prop="house_number">
        <el-input v-model="formData.house_number" placeholder="请输入门牌号" />
      </el-form-item>
    

      <el-form-item label="所属项目:" prop="project_id">
        <el-select v-model="formData.project_id" placeholder="所属项目">
          <el-option
            v-for="item in projectList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>

    
      <el-form-item>
        <el-button @click="handleCancle">取 消</el-button>
        <el-button type="primary" @click="handleSubmit"> 确 定 </el-button>
      </el-form-item>
    </el-form>
  </section>
</template>

<script>
import { mapState } from "vuex";
import { validPhone } from "@utils/validate"; //引入手机号码校验规则
import ueditorcom from "@/components/ueditorcom";
import { ownerUpdate, ownerAdd } from "@api/user";
import { getProjectList } from "@api/project";
export default {
  name: "ownerForm",
  inject: ["reload"],
  /**
   * 组件
   */
  components: {
    ueditorcom,
  },
  data() {
    // 校验手机号码
    let phoneValidator = (rule, value, callback) => {
      if (!value) {
        callback();
      } else {
        if (!validPhone(value)) {
          callback(new Error("手机号码格式错误！"));
        }
        callback();
      }
    };
    return {
      id: null, //修改用户id
      imageUrl: "", //图片上传成功回显链接
      formData: {
        checkList: [],
        status:2,
        position_rank:3
      }, //表单存储数据
      companyListTree: [],
      projectList: [],
      rules: {
        name: [{ required: true, message: "请输入业主名称", trigger: "blur" }],
        project_id: [
          { required: true, message: "请选择所属项目", trigger: "change" },
        ],
      }, //表单校验规则
    };
  },
  computed:{
        ...mapState("system", ["companyListSearch"]),
  },
  async created() {
    if (this.$route.params.id) {
      this.id = this.$route.params.id;
      this.getDetail();
    }
    
    let res2 = await getProjectList();
    this.projectList = res2.data;
  },
  mounted() {},
  methods: {
    /** 修改前获取详情信息 */
    async getDetail() {
      /**
       * 请求接口获取详情数据
       */
      let res = await ownerUpdate({ id: this.id }, "get");
      res.data.status = Number(res.data.status);
      res.data.password = "";
      this.formData = {
        ...this.formData,
        ...res.data,
      };
      this.imageUrl = res.data.thumb_path;

   

      //请求返回成功后打开弹窗
      this.formDialogVisible = true;
    },
    /** 取消用户编辑 */
    handleCancle() {
      this.$refs["formData"].clearValidate();
      this.$router.go(-1);
    },
    /** 提交用户信息*/
    handleSubmit() {
      this.formData = {
        ...this.formData,
      };
      this.$refs["formData"].validate(async (valid) => {
        if (valid) {
          /**
           * 请求接口
           */
          // delete this.formData.delete_time;
          // delete this.formData.update_time;
          if (this.id) {
            // 存在id，执行编辑请求
            var res = await ownerUpdate(this.formData, "post");
          } else {
            // 不存在id，执行添加请求
            var res = await ownerAdd(this.formData);
          }
          if (res.code == 200) {
            this.$message({
              message: res.msg,
              type: "success",
            });
            // 请求后，不论是否成功，调用方法关闭弹窗

            this.handleCancle();
          }
        }
      });
    },
    /** 上传前处理 */
    uploadBefore(file) {
      const isImg =
        file.type == "image/jpeg" ||
        file.type == "image/jpg" ||
        file.type == "image/png" ||
        file.type == "image/gif";
      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isImg) {
        this.$message.error("上传图片只能是 .jpg,.jpeg,.png,.gif 格式!");
      }
      if (!isLt2M) {
        this.$message.error("上传图片大小不能超过 2MB!");
      }
      return isImg && isLt2M;
    },
    /** 上传成功 */
    uploadSuccess(res, file) {
      this.imageUrl = URL.createObjectURL(file.raw);
      this.formData = {
        ...this.formData,
        thumb: res.data.list[0].id,
      };
    },
    /** 上传失败 */
    uploadError(err, file) {
      this.$message.warning("上传失败");
    },
  },
};
</script>